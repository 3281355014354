<template>
  <v-menu
    ref="menuref"
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        label="Fecha de Nacimiento"
        :rules="$props.rules"
        :prepend-icon="icons.mdiCalendar"
        readonly
        v-bind="attrs"
        v-on="on"
      ></v-text-field>
    </template>

    <v-date-picker
      ref="picker"
      v-model="date"
      :max="new Date().toISOString().substr(0, 10)"
      min="1950-01-01"
      :active-picker="activePicker"
      color="primary"
      locale="es"
      @change="save"
    ></v-date-picker>
  </v-menu>
</template>

<script>
/* eslint-disable no-unused-expressions */
import { ref, watch } from 'vue'
import { mdiCalendar } from '@mdi/js'

export default {
  props: {
    value: {},
    rules: {
      type: Array,
    },
  },
  setup(props, { emit }) {
    const date = ref()
    const menu = ref(false)
    const picker = ref(null)
    const menuref = ref(null)

    const activePicker = ref('YEAR')
    watch(menu, val => {
      // eslint-disable-next-line no-return-assign
      val && setTimeout(() => (activePicker.value = 'YEAR'))
    })

    const save = d => {
      menuref.value.save(d)
      emit('input', d)
    }

    if (props.value) date.value = new Date(props.value).toISOString().substr(0, 10)

    return {
      date,
      menu,
      picker,
      menuref,
      save,
      activePicker,

      // icons
      icons: {
        mdiCalendar,
      },
    }
  },
}
</script>
