<template>
  <v-card flat class="pa-3 mt-2">
    <v-card-text class="d-flex">
      <v-avatar color="primary" size="120" class="v-avatar-light-bg primary--text me-6" rounded>
        <v-img v-if="avatarUrl" :src="avatarUrl"></v-img>
        <span v-else class="font-weight-semibold text-5xl">{{
          avatarText(profileDataLocale.name + ' ' + profileDataLocale.lastname)
        }}</span>
      </v-avatar>

      <!-- upload photo -->
      <div>
        <v-btn color="primary" class="me-3 mt-5" @click="$refs.filePicker.click()" small>
          <v-icon class="d-sm-none">
            {{ icons.mdiCloudUploadOutline }}
          </v-icon>
          <span class="d-none d-sm-block">Subir Avatar</span>
        </v-btn>

        <input
          ref="filePicker"
          @change="preUploadAvatar($event.target.files)"
          type="file"
          accept="image/png, image/jpg, image/gif, image/jpeg"
          :hidden="true"
        />

        <v-btn color="error" outlined class="mt-5" @click=";(avatarUrl = ''), (profileDataLocale.avatar = '')" small>
          Limpiar
        </v-btn>
        <p class="text-sm mt-5">Formatos permitidos JPG, GIF or PNG.</p>
      </div>
    </v-card-text>

    <v-card-text>
      <v-form v-model="valid" ref="form" class="multi-col-validation mt-6" @submit.prevent="onSubmit">
        <v-row>
          <v-col md="6" cols="12">
            <v-text-field
              v-model="profileDataLocale.name"
              :rules="[validators.required]"
              disabled
              outlined
              dense
              label="Nombre"
            ></v-text-field>
          </v-col>

          <v-col md="6" cols="12">
            <v-text-field
              v-model="profileDataLocale.lastname"
              :rules="[validators.required]"
              disabled
              outlined
              dense
              label="Apellido"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="profileDataLocale.document"
              disabled
              :rules="[validators.required]"
              outlined
              dense
              label="Documento"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <datepicker-birthday-picker
              :rules="[validators.required]"
              v-model="profileDataLocale.birthday"
            ></datepicker-birthday-picker>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="profileDataLocale.email"
              :rules="[validators.required, validators.emailValidator]"
              outlined
              dense
              type="email"
              label="Email"
              placeholder="Email"
              hide-details="auto"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="profileDataLocale.phone"
              :rules="[validators.required]"
              outlined
              dense
              label="Teléfono"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="profileDataLocale.address"
              :rules="[validators.required]"
              outlined
              dense
              label="Dirección"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <v-text-field
              v-model="profileDataLocale.city"
              :rules="[validators.required]"
              outlined
              dense
              label="Ciudad"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-btn color="primary" class="me-3 mt-4" type="submit"> Guardar </v-btn>
            <v-btn color="secondary" outlined class="mt-4" type="reset" @click.prevent="resetForm"> Resetear </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { required, emailValidator } from '@core/utils/validation'
import { mdiAlertOutline, mdiCloudUploadOutline } from '@mdi/js'
import { avatarText } from '@core/utils/filter'
import { ref, nextTick } from 'vue'
import router from '@/router'
import store from '@/store'
import datepickerBirthdayPicker from '../user/user-view/user-bio-panel/DatepickerBirthdayPicker.vue'
import snackbarPlugin from '@/plugins/snackbar'

export default {
  components: {
    datepickerBirthdayPicker,
  },
  props: {
    profileData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const profileDataLocale = ref(JSON.parse(JSON.stringify(props.profileData)))

    const resetForm = () => {
      profileDataLocale.value = JSON.parse(JSON.stringify(props.profileData))
    }

    const valid = ref(false)
    const form = ref(null)
    const avatarUrl = ref(
      profileDataLocale.value.avatar
        ? store.state.apidomain + '/user/avatars/' + profileDataLocale.value.avatar + '?rand=' + Date.now()
        : null,
    )
    const filePicker = ref(null)

    const preUploadAvatar = files => {
      if (files.length && files[0].type.includes('image/')) {
        avatarUrl.value = URL.createObjectURL(files[0])
      } else {
        snackbarPlugin.showMessage({ content: 'Formato de imagen incorrecto', color: 'error', timeout: 10000 })
      }
    }

    const onSubmit = () => {
      if (valid.value) {
        store
          .dispatch('user/updateProfile', profileDataLocale.value)
          .then(async r => {
            await uploadAvatar()
            snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
          })
          .catch(e => {
            snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
          })
      } else {
        validate()
      }
    }

    const validate = () => {
      form.value.validate()
    }

    const uploadAvatar = async () => {
      if (filePicker.value && filePicker.value.files.length) {
        let formdata = new FormData()
        formdata.append('userId', profileDataLocale.value._id)
        formdata.append('avatar', filePicker.value.files[0])

        let r = await store.dispatch('user/uploadAvatar', formdata)

        if (r.status == 200) {
          nextTick(() => {
            profileDataLocale.value.avatar = r.data.avatar
            avatarUrl.value =
              store.state.apidomain + '/user/avatars/' + profileDataLocale.value.avatar + '?rand=' + Date.now()
            filePicker.value = null
            store.commit('user/setAvatar', profileDataLocale.value.avatar)
            router.go()
          })
          return true
        } else return false
      } else return true
    }

    return {
      filePicker,
      validators: { required, emailValidator },
      valid,
      form,
      onSubmit,
      avatarUrl,
      preUploadAvatar,
      profileDataLocale,
      resetForm,
      avatarText,
      icons: {
        mdiAlertOutline,
        mdiCloudUploadOutline,
      },
    }
  },
}
</script>
