<template>
  <v-card flat class="pa-3 mt-2">
    <v-row
      ><v-col cols="12" sm="8" md="6">
        <v-card-title>
          <v-icon size="26" class="text--primary me-3">
            {{ icons.mdiSchool }}
          </v-icon>
          <span>Cursos</span>
        </v-card-title>

        <!-- Activity -->
        <v-card-text>
          <v-switch v-model="notifications.enrollCourse" hide-details class="mt-0">
            <template #label>
              <span class="text-sm ms-2">Enviarme un email cuando me matriculen en un nuevo curso</span>
            </template>
          </v-switch>

          <v-switch v-model="notifications.newQualy" hide-details class="mt-3">
            <template #label>
              <span class="text-sm ms-2">Enviarme un email cuando tenga una calificación nueva</span>
            </template>
          </v-switch>

          <v-switch v-model="notifications.updateQualy" hide-details class="mt-3">
            <template #label>
              <span class="text-sm ms-2">Enviarme un email cuando me actualicen una calificación</span>
            </template>
          </v-switch>
        </v-card-text></v-col
      >

      <v-col cols="12" sm="4" md="6" class="d-none d-sm-flex position-relative">
        <v-img contain max-width="350" src="@/assets/images/3d-characters/4034449.webp"></v-img>
      </v-col>
    </v-row>
    <v-card-text>
      <v-btn color="primary" class="me-3 mt-5" @click="saveNotifications"> Guardar </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiSchool, mdiEmailOutline } from '@mdi/js'
import { ref } from 'vue'
import snackbarPlugin from '@/plugins/snackbar'
import store from '@/store'

export default {
  props: {
    profileData: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const notifications = ref(
      props.profileData.notifications
        ? JSON.parse(JSON.stringify(props.profileData.notifications))
        : {
            enrollCourse: true,
            updateQualy: true,
            newQualy: true,
          },
    )

    const saveNotifications = () => {
      store
        .dispatch('user/updateNotifications', { user: props.profileData._id, notifications: notifications.value })
        .then(async r => {
          snackbarPlugin.showMessage({ content: 'Guardado Correctamente', color: 'success', timeout: 10000 })
        })
        .catch(e => {
          snackbarPlugin.showMessage({ content: e.response.data.message, color: 'error', timeout: 10000 })
        })
    }

    return {
      saveNotifications,
      notifications,
      icons: { mdiSchool, mdiEmailOutline },
    }
  },
}
</script>
